<template>
  <div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else class="min-h-screen">
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">Answer of Student</h1>
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="form-group m-form__group grid grid-cols-4 justify-start gap-4 mb-6">
          <div class="">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Search By Attendance Report Id</label>
            <div class="relative">
              <TextField type="text" :enterKeyAction="onSearch" withIcon borderEnabled v-model="options['absensi_report_id']" placeholder="Attendance Report Id" />
              <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                <Search />
              </div>
            </div>
          </div>
        </div>
        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: perPage,
            position: 'bottom',
            perPageDropdown: [5, 10],
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All'
          }"
          :totalRows="totalRecords"
          :rows="data"
          :columns="columns"
          :sort-options="{
            enabled: false
          }"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange"
          :row-style-class="rowStyleClassFn"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'btn'" class="flex flex-col gap-2">
              <Button buttonText="Edit" type="tertiary" size="full" @action="editMaster(props.row.id)" />
              <Button buttonText="Delete" type="delete" @action="deleteMaster(props.row.id)" />
            </div>
            <span v-else-if="props.column.field == 'exam_url'">
              <a :href="props.row.exam_url" target="_blank"> {{ props.row.exam_url }}</a>
            </span>
            <span v-else-if="props.column.field == 'exam_url_array'">
              <a v-for="(item_exam, item_exam_index) in props.row.exam_url_array" :href="item_exam" target="_blank" :key="'exam-' + item_exam_index"> {{ item_exam }}</a>
            </span>
            <div v-else-if="props.column.field === 'created_date'">
              <span style="display: block; white-space: nowrap">
                {{ formatDate(props.formattedRow[props.column.field]).date }}
              </span>
              <span style="display: block; font-size: 14px; color: #333">
                {{ formatDate(props.formattedRow[props.column.field]).time }}
              </span>
            </div>
            <span v-else-if="props.column.field == 'exam_sheet'">
              <a :href="'https://docs.google.com/spreadsheets/d/' + props.row.exam_sheet" target="_blank"> {{ props.row.exam_sheet }}</a>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
export default {
  name: 'Answer',
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search')
  },
  mounted: function () {
    this.initData()
  },
  data: function () {
    return {
      searchTerm: null,
      options: {
        limit: 11,
        page: 1,
        sort: '',
        absensi_report_id: ''
      },
      //columns: [],
      perPage: 10,
      showProgress: true,
      counterProgress: 0,
      modeForm: '',
      locked: false,
      unlockPassword: '',
      stillLoading: true,
      testingName: ''
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    formatDate(date) {
      if (date) {
        return {
          date: moment(date).local().format('DD MMM YYYY'),
          time: moment(date).local().format('HH:mm:ss')
        }
      } else {
        return '-'
      }
    },
    initData() {
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      let paramsTemp = queryString.stringify({
        ...{
          page: 1,
          limit: 100
        },
        ...this.options
      })
      this.$store
        .dispatch('answer/GET_LIST_ANSWER', {
          masterType: this.$route.meta.name,
          customerId: this.clientId,
          params: paramsTemp
        })
        .then(() => {
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
        })
    },
    unlock() {
      if (this.unlockPassword == this.$store.getters['customer/customer'].password) {
        this.locked = false
      } else {
        alert('Password infalid')
      }
    },
    preview(masterId) {
      this.$router.push('/answer/' + masterId)
    },
    onPageChange(params) {
      this.showLoading()
      this.options.limit = params.currentPerPage
      this.options.page = params.currentPage
      let paramsTemp = queryString.stringify({
        ...{
          limit: params.currentPerPage,
          page: params.currentPage,
          sort: ''
        },
        ...this.options
      })
      this.$store
        .dispatch('answer/GET_LIST_ANSWER', {
          masterType: this.$route.meta.name,
          customerId: this.clientId,
          params: paramsTemp,
          testingName: this.$route.meta.testingName
        })
        .then(() => {
          this.hideLoading()
        })
    },

    onSearch(event) {
      this.options.page = 1
      let paramsTemp = queryString.stringify({
        ...{
          limit: 100,
          page: 1,
          nama: null,
          sort: '',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })
      this.showLoading()
      this.$store
        .dispatch('answer/GET_LIST_ANSWER', {
          masterType: this.$route.meta.name,
          customerId: this.clientId,
          params: paramsTemp,
          testingName: this.$route.meta.testingName
        })
        .then(() => {
          this.hideLoading()
        })
    },
    editMaster(masterId) {
      this.$router.push('/answer/edit/' + masterId)
    },
    deleteMaster(masterId) {
      let paramsTemp = queryString.stringify({
        ...{
          limit: 100,
          page: 1,
          nama: null,
          sort: '',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })

      this.$dialog
        .confirm('Are you sure you want to delete this?', {
          okText: 'Yes',
          cancelText: 'No',
          loader: true,
          animation: 'bounce'
        })
        .then((dialog) => {
          dialog.close()
          this.$store
            .dispatch('answer/DELETE_ANSWER', {
              masterType: this.$route.meta.name,
              masterId: masterId,
              testingName: this.$route.meta.testingName
            })
            .then((resp) => {
              if (resp.data.status == '200 OK') {
                // this.$swal(resp.data.message)
                this.$store.dispatch('answer/GET_LIST_ANSWER', {
                  masterType: this.$route.meta.name,
                  customerId: this.clientId,
                  params: paramsTemp
                })
              }
            })
        })
        .catch(function () {})
    },

    addData() {
      this.$router.push('/' + this.$route.meta.name + '/Add')
    },
    openDisplay() {
      this.$router.push('/display/' + this.$store.getters['customer/customer'].token_trx)
    },
    resetDisplay() {
      this.$store.dispatch('customer/RESET_TOKEN_TRX', {}).then((resp) => {
        if (resp.data.status == '200 OK') {
          // this.$swal(resp.data.status)
        }
      })
    },
    rowStyleClassFn(row) {
      let result = 'default'
      // if (row.id % 2 == 1 ){
      //     result='green'
      // } else if (row.id == 0 ){
      //     result='red'
      // }
      return result
    }
  },
  computed: {
    clientId() {
      return localStorage.getItem('client')
    },
    data: function () {
      return this.$store.getters['answer/list_answer'] ? this.$store.getters['answer/list_answer'] : []
    },
    totalRecords: function () {
      return this.$store.getters['answer/paginate'].total ? this.$store.getters['answer/paginate'].total : 0
    },
    columns: function () {
      let tempColumnsNew = [
        { field: 'id', label: 'Id' },
        { field: 'absensi_report_id', label: 'Attendance Report Id' },
        { field: 'exercise_id', label: 'Exercise Id' },
        { field: 'exercise_type_name', label: 'Exercise Type' },
        { field: 'score', label: 'Score' },
        { field: 'answer', label: 'Answer' },
        { field: 'account_id', label: 'Account Id' },
        { field: 'quiz_index', label: 'Quiz Index' },
        { field: 'created_date', label: 'Created Date' }
        // { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]

      return tempColumnsNew
    },
    level_id: function () {
      return this.$store.getters['customer/customer'] ? this.$store.getters['customer/customer'].level_id : 0
    }
  },
  created: function () {
    this.initData()
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    }
  }
}
</script>
<style scoped lang="scss">
.red {
  background: linear-gradient(#f4f5f8, #e2133f);
}
.green {
  background: linear-gradient(#f4f5f8, #57c137);
}
.default {
  background: linear-gradient(#f4f5f8, #f1f3f6);
}
.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}
</style>
